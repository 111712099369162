import { Component, Vue, Prop } from 'vue-property-decorator'
import http from '@/services/http'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { IItem } from '@/Models'
import { Company } from '@/Models/Company'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CompanyModal extends Vue {
  @Prop({ required: false })
  companyReplace!: Company
  @Prop({ required: false })
  action!: string

  company: Company = new Company()

  isLoading = false

  mounted() {
    if (this.action == 'edit') {
      Object.assign(this.company, this.companyReplace)
    } else {
      this.company = new Company()
    }
  }

  confirmation() {
    this.isLoading = true
    if (this.action == 'edit') {
      this.updateCompany()
    } else {
      this.createCompany()
    }
  }

  async createCompany() {
    try {
      await http.post('panel/company/', this.company)
      this.$buefy.toast.open({
        message: 'Empresa agregada',
        type: 'is-success',
      })
      this.isLoading = false
      this.close()
    } catch (error) {
      console.error(error)
      this.isLoading = false
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }
  async updateCompany() {
    this.company
    try {
      await http.put(`panel/company/${this.company.id}/`, this.company)
      this.$buefy.toast.open({
        message: 'Empesa actualizada',
        type: 'is-success',
      })

      this.isLoading = false
      this.close()
    } catch (error) {
      console.error(error)
      this.isLoading = false
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }

  close() {
    this.$emit('closeForm')
  }
}
